/* make the customizations */
$theme-colors: (
    "primary": #3C4740,
    "secondary": #B69976,
);
$body-bg: #000;
$enable-rounded: false;
$border-radius: 0;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.btn-outline-secondary {
    min-width: 10em;
    --bs-btn-color: #fff;
}