.concept {
    padding-top: 20vh !important;
    .concept-card {
        padding-bottom: 50px;
        &--content {
            height: 45vh;
            padding: 0 1rem 20px;
            overflow: auto;
            text-align: left;
            font-size: 0.85rem;
            h3 {
                font-size: 1rem;
                color: var(--bs-secondary)
            }
            h4 {
                font-size: 0.8rem;
                color: var(--bs-secondary)
            }
            p {
                font-size: 0.5rem;
            }
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                font-size: 0.3em;
                li {
                    position: relative;
                    span {
                        position: absolute;
                        font-family: 'Raleway';
                        color: #3C4740;
                        &:before {
                            top: -0.5em;
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            content: '';
                            background-color: #3C4740;
                            opacity: 0.5;
                        }
                    }
                    p {
                        margin: 1em 0 1em 2.5em;
                        font-size: 1em;
                    }
                }
            }
            .video-wp {
                width: 100%;
                height: 0;
                position: relative;
                padding-bottom: 66%;
                iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

.carousel-indicators{
    [data-bs-target] {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: none;
        background: var(--bs-secondary);
        //opacity: 1;
    }
    .active {
    }
}