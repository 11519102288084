@import 'bootstrap-custom';

html, body {
    height: 100vh;
    margin: 0;
    padding: 0;
}
body {
    #root {
        position: relative;
        overflow: hidden;
        height: 100vh;
        margin: 0;
        padding: 0;
    }
}
img {
    max-width: 100%;
}