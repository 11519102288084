.home {
    cursor: pointer;
    &--video-cover {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    &--video {
        //opacity: 0.25;
        transition: .3s all ease-in-out;
    }
    &--screen {
        position: relative;
        &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            content: '';
            background: rgba(0,0,0,0.5);
        }
    }
    &--deco {
        width: 23%;
        height: 0;
        display: block;
        position: absolute;
        background: no-repeat center center;
        background-size: contain;
        &--1 {
            width: 28%;
            left: 16%;
            bottom: -3%;
            padding-bottom: 35%;
        }
        &--2 {
            right: 0;
            top: 20%;
            padding-bottom: 30%;
        }
    }
    &--col {
        padding-bottom: 15vh;
    }
    &--company-logo {
        padding: 0 35%;
        margin-bottom: 30vh;
    }
    &--main-logo {
        padding: 0 20%;
        display: block;
        margin-bottom: 20vh;
    }
    &--btn-highlight {
        opacity: 1 !important;
    }
}
.btn {
    opacity: 0.25;
    font-weight: 600;
    transition: .5s all ease-in-out;
}